document.addEventListener("DOMContentLoaded", function(event) {
    /*--------------------------
     Affichage dessin SVG
     -------------------------*/

    var target = document.querySelectorAll(".loadSvg");
    for (var i = 0; i < target.length; i++) {
        create(target[i]);
    }

    function create(t) {
        // create an observer instance
        var observer = new MutationObserver(function(mutations) {

            mutations.forEach(function(mutation) {
                var id = t.id;
                if(t.classList.contains("aos-animate") && t.classList.contains("loadSvg")){

                    animSvg('#'+ t.id);
                    t.classList.remove('loadSvg');
                }else if(t.classList.contains("aos-init") && !t.classList.contains('aos-animate') && !t.classList.contains('loadSvg')){
                    t.classList.add('loadSvg');
                }

            });
        });
        // configuration of the observer
        var config = {
            attributes: true
        };

        // pass in the target node, as well as the observer options
        observer.observe(t, config);
    }


    /*------------------------------*/
    /* Animation Logo */
    /*------------------------------*/
    var animSvg = function(selector){
        var path = document.querySelector(selector+' path');
        var length = path.getTotalLength();
        // Clear any previous transition
        path.style.transition = path.style.WebkitTransition =
            'none';
        // Set up the starting positions
        path.style.strokeDasharray = length + ' ' + length;
        path.style.strokeDashoffset = length;
        // Trigger a layout so styles are calculated & the browser
        // picks up the starting position before animating
        path.getBoundingClientRect();
        // Define our transition
        path.style.transition = path.style.WebkitTransition =
            'stroke-dashoffset 5s ease-in-out';
        // Go!
        path.style.strokeDashoffset = '0';


        var circle =  document.querySelector(selector+' circle');
        if(circle) {
            var radius = circle.getAttribute("r");
            var circleLength = 2 * Math.PI * radius;
            // Clear any previous transition
            circle.style.transition = circle.style.WebkitTransition =
                'none';
            // Set up the starting positions
            circle.style.strokeDasharray = circleLength;// + ' ' + length;
            circle.style.strokeDashoffset = circleLength;
            // Trigger a layout so styles are calculated & the browser
            // picks up the starting position before animating
            //path.getBoundingClientRect();
            // Define our transition
            circle.style.transition = path.style.WebkitTransition =
                'stroke-dashoffset 5s ease-in-out';
            // Go!
            circle.style.strokeDashoffset = '0';
        }
    };


    /*--------------------------
     Affichage compteur
     -------------------------*/


    var target2 = document.querySelectorAll(".loadCount");
    for (var i = 0; i < target.length; i++) {

        createCount(target2[i]);
    }

    function createCount(t) {
        // create an observer instance
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {

                var id = t.id;

                if(t.classList.contains("aos-animate") && t.classList.contains("loadCount")){

                    if(t.firstChild.nextElementSibling.classList.contains("count-decimal")){
                        //countFunctionDec('#'+id+ 'span.count-decimal');
                        countFunctionDec();
                    }
                    else if(t.firstChild.nextElementSibling.classList.contains("count")){
                        //countFunction('#'+id+ 'span.count');
                        countFunction();
                    }

                }

            });
        });
        // configuration of the observer
        var config = {
            attributes: true
        };

        // pass in the target node, as well as the observer options
        observer.observe(t, config);
    }


});