// SCLI
// link to pharmacovigilance + open tab
if ($('.faq-content').length) {

    /** * Navigate & open tab ** */
    const anchorName = document.location.hash;
    const anchorId = anchorName.match(/\d+/);

    $('body').animate({'scrollTop': 0}, 10, () => {
        if($(anchorName).length) {
            $('body').animate({'scrollTop': $(anchorName).offset().top}, 1000, () => {
                $(`${anchorName  } > h2`).trigger("click");
            });
        }
    });

    /** * ANIM ON CLICK ** */
    $('.panel-collapse').on('shown.bs.collapse', function (e) {
        const $panel = $(this).closest('.panel');
        $('html,body').animate({
            scrollTop: $panel.offset().top
        }, 1000);
    });
}

$(() =>{
    const urlParams = new URLSearchParams(window.location.search)
    const questionToOpen = urlParams.get('question')
    if (questionToOpen) {
        $(`[data-anchor-id="${  questionToOpen }"] h2`).click()
    }
});
