//FORMATIONS
//init sliders
if($('.listFormationsCategory').length){
    $('.listFormationsCategory').each(function(){
        var nbSlides = $(this).children('.containerFormation').children('article').length;
        if(nbSlides > 2){
            var slider = $(this).bxSlider({
                minSlides: 1,
                maxSlides: 3,
                slideWidth: 275,
                slideMargin: 50,
                prevText: '',
                nextText: ''
            });

            if($(this).parent().parent().css('maxWidth') == '275px' && $(window).width() >= 500){
                slider.destroySlider();
            }
        }
    })
}

if($('.modules-list').length && $(window).width() >= 500){
    $('.modules-list').each(function(){
        var nbSlides = $(this).children('.module').length;
        if(nbSlides > 1){
            $(this).bxSlider({
                minSlides: 1,
                maxSlides: 4,
                slideWidth: 278,
                slideMargin: 15,
                prevText: '',
                nextText: '',
                infiniteLoop: false,
                pager: false,
                hideControlOnEnd: true
            });
        }
    })
}

if ($('#graph-circle').length > 0) {
    var target = $('#graph-circle');
    var chartValue = target.data('value');

    new Chart(target, {
        type: 'doughnut',
        data: {
            datasets: [{
                data: [chartValue, (100 - chartValue)],
                backgroundColor: [
                    '#4e8be5',
                    '#dcecfe'
                ],
                borderWidth: 0
            }]
        },
        options: {
            cutoutPercentage: 80,
            responsive: false,
            tooltips: {
                enabled: false
            }
        }
    });
}

// Slider video formations
if ($('.videoslider-container').length > 0) {
    /* pour éviter l'affichage de toutes les images au chargement de la page : */
    $('.videoslider-container img').show();

    $('#videoslider').bxSlider({
        pagerCustom: '#videoslider-nav',
        mode: "horizontal",
        useCSS: false
    });

    var nbSlidesVideo = $("#videoslider-nav > a").length;
    if(nbSlidesVideo > 3){
        $("#videoslider-nav").bxSlider({
            minSlides: 1,
            maxSlides: 4,
            slideWidth: 174,
            slideMargin: 0,
            prevText: '',
            nextText: '',
            infiniteLoop: false,
            pager: false,
            hideControlOnEnd: true,
            wrapperClass: 'bx-wrapper bx-wrapper-video-slider-formation'
        });
    }




}
