if ($("#officine-orders-section").length > 0) {
    $('.cell-action a').click(function (event) {
        event.stopPropagation();
    });

    $('.cell-triggered').click(function (event) {
        const detailsLink = document.createElement("a");
        detailsLink.href = event.currentTarget.parentElement.id;
        detailsLink.click();
    });

    $('.order-hidden-image').hide();

    $('.order-infos-icon').mouseenter(function () {
        $(this).next('.order-hidden-image').show();
    });

    $('.order-infos-icon').mouseleave(function () {
        $(this).next('.order-hidden-image').hide();
    });

    $('#order_filters_section').change(function () {
        const val = $(this).val();
        const showMoreLink = $('#showMoreLink');
        if (val && showMoreLink) {
            showMoreLink.attr('href', showMoreLink.attr('href').replace(/section=[a-z]*/, 'section=' + val));
        }
    })
}

if ($('.detail-commande-header-bloc2-container-promotional-block').length && $(window).width() < 480) {
    $('.detail-commande-header-bloc2-container-promotional-block').each(function () {
        var nbSlides = $(this).children('.bxslider').length;
        if (nbSlides > 2) {
            var slider = $(this).bxSlider({
                minSlides: 1,
                maxSlide: 3,
                slideWidth: 320,
                prevText: '',
                nextText: '',
                responsive: true,
                wrapperClass: 'bx-wrapper bx-wrapper-commande-slider'
            });
        }

        $(".img-slider").css({
            "margin-top": 5 + "px",
            "width": 280 + "px",
            "height": 225 + "px"
        });

        $(".img-slider-service-client").css({
            "width": 280 + "px",
            "height": 240 + "px"
        });
    })
}

window.orderProcessed = function (orderNrid, orderUrl) {
    $.ajax({
        type: "POST",
        url: orderUrl,
        data: {
            'orderNrid': orderNrid
        },
        success: (result) => {
            if (result.success) {
                const orderFavDiv = $('#' + orderNrid);
                if (orderFavDiv.hasClass('favorite')) {
                    $('.order-' + orderNrid).removeClass('favorite');
                } else {
                    $('.order-' + orderNrid).addClass('favorite');
                }
            }
        },
        error: (error) => {
            console.log(error);
        }
    });
};
