var timer = 0;
var percentage = 0;

/**
 * default action of minute pharma
 */
mpIndexPageAction();

/**
 *  default action of carousel minute pharma
 */
carouselIndexPageAction();

// if is a ajax call
$(document).ajaxStop(function () {
    mpIndexPageAction();
    carouselIndexPageAction();
});

//check if, user is at the page mp-answer-page and ajax is stoped
$(document).ajaxStop(function () {
    if ($("#mp-answer-page, #mp-modules-dispo, .cmp-answer-page").length > 0) {
        clearTimeout(timer);
        percentage = 0;
        animateUpdate();

        setTimeout(function () {
            $("#mp-answer-page, .cmp-answer-page").hide();
            $("#mp-answer-footer, .cmp-answer-footer").hide();
            $("#mp-result-page, .cmp-result-page").show();
            $("#mp-result-footer, .cmp-result-footer").show();
        }, 3000);
    }
    nextQuestionAction();
});

/**
 * check if user is at the page of modules-dispo
 */
if ($("#mp-modules-dispo").length > 0) {
    nextQuestionAction();
}

/**
 * check if user is at the page of modules-restart
 */
if ($("#mp-modules-restart").length > 0) {
    mpIndexPageAction();
}


/**
 * action to next question
 */
function nextQuestionAction() {
    $("#mp-result-btn-next, .cmp-result-btn-next").click(function (event) {
        event.preventDefault();
        $(".mp__bx-loading").show();
        let pathAction = $(this).data("action");
        $.ajax({
            type: 'POST',
            url: pathAction,
            dataType: 'html',
            success: function (data) {
                $(".mp__bx-loading").hide();
                $('#mp-container, .cmp__container').html(data);
            }
        });
    });
}

/**
 * minite pharma index page action
 */
function mpIndexPageAction() {
    //check if, user is at the page mp-index-page
    if ($("#mp-index-page, #mp-score-page, #mp-modules-restart").length > 0) {
        $(".btn-send-answer, #mp-restart-btn").click(function (event) {
            event.preventDefault();
            $(".mp__bx-loading").show();
            $(".btn-send-answer").hide();
            let pathAction = $(this).data("action");
            $.ajax({
                type: 'POST',
                url: pathAction,
                dataType: 'html',
                success: function (data) {
                    $(".mp__bx-loading").hide();
                    $('#mp-container').html(data);
                }
            });
        });
    }
}

/**
 * function for progress bar animation
 */
function animateUpdate() {
    percentage++;
    $('#pbar_innerdiv, .pbar_innerdiv').css("width", percentage + "%");
    if (percentage < 100) {
        timer = setTimeout(animateUpdate, 30);
    }
}

/**
 * carousel
 */
function carouselIndexPageAction() {
    if ($("#carousel-question-card").length > 0) {
        $(".btn-send-answer").click(function (event) {
            event.preventDefault();
            $(".mp__bx-loading").show();
            $(".btn-send-answer").hide();
            let pathAction = $(this).data("action");
            $.ajax({
                type: 'POST',
                url: pathAction,
                dataType: 'html',
                success: function (data) {
                    $(".mp__bx-loading").hide();
                    $('.cmp__container').html(data);
                }
            });
        });
    }
}

for (let i = 1; i <= 6; i++) {
    if ($(".cmp_valide_card_container_" + i).length > 0) {
        $(".cmp-restart-btn_" + i).click(function (event) {
            event.preventDefault();
            let toggle = $(this).data("toggle");
            let pathAction = $(this).data("action");
            $(".mp__bx-loading_" + toggle).show();
            $(".cmp-restart-btn_" + toggle).hide();
            $.ajax({
                type: 'POST',
                url: pathAction,
                dataType: 'html',
                success: function (data) {
                    $(".mp__bx-loading_" + toggle).hide();
                    $(".cmp_valide_card_container_" + toggle).html(data);
                    for (let i = 1; i <= 6; i++) {
                        $(".cmp-restart-btn_" + i).replaceWith('<a class="cmp__module_valid_card__btn cmp__infobulle"\n' +
                            '               aria-label="Veuillez terminer tous les modules">\n' +
                            '                Recommencer\n' +
                            '            </a>')
                    }
                }
            });
        });
    }
}
