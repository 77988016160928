
if ($('#popinDisplayCgu').length > 0) {
    require('lity')
    var lightbox = lity('#popinDisplayCgu', {
        'esc': false,
        'template': '<div class="lity" tabindex="-1"><div class="lity-wrap"><div class="lity-loader">Loading...</div><div class="lity-container"><div class="lity-content"></div></div></div></div>'
    });

    $(".popin-close").click(function () {
        lightbox.close();
    });

    $("#btn-accept-cgu").click(function (event) {
        event.preventDefault();
        $("#btn-accept-cgu").prop("disabled", true);
        $("#btn-accept-cgu").css('background-color', 'grey');
        let pathAction = $(this).data("action");
        $.ajax({
            type: 'POST',
            url: pathAction,
            dataType: 'json',
            success: function (data) {
                if (true === data.create) {
                    lightbox.close();
                }
            }
        });
    });
}
