if ($('form.cata_filters').length > 0 || $('.prod-content-caracteristiques').length > 0) {

    //Scrollbar
    if ($('.cata_filters-excipients-listing').length > 0) {
        $(".cata_filters-excipients-listing").niceScroll({
            cursorcolor: "#7f8ca2",
            background: "#dbe1e8",
            cursorwidth: "5px",
            cursorborder: 0,
            cursorborderradius: 0,
            horizrailenabled: false
        });
    }

// Catalogue filters
    if ($('.cata_filters-mobile-header').length > 0 && $('.catalogue_content-leftcol').css('float') === "none") {
        $('.cata_filters-mobile-header').on('click', function () {
            $('.cata_filters-mobile-content').slideToggle(500);
            $(this).toggleClass('open');
        });
    }

// Catalogue search Excipients
    var highlight = function (string) {
        $("#listExcipient .match label").each(function () {
            var matchStart = $(this).text().toLowerCase().indexOf("" + string.toLowerCase() + "");
            var matchEnd = matchStart + string.length - 1;
            var beforeMatch = $(this).text().slice(0, matchStart);
            var matchText = $(this).text().slice(matchStart, matchEnd + 1);
            var afterMatch = $(this).text().slice(matchEnd + 1);
            $(this).html(beforeMatch + "<strong>" + matchText + "</strong>" + afterMatch);
        });
    };

    /* filter products */
    $("#search-excipient").on("keyup click input", function () {
        $("#listExcipient .form-group").removeClass("match").hide().filter(function () {
            return $(this).text().toLowerCase().indexOf($("#search-excipient").val().toLowerCase()) !== -1;
        }).addClass("match").show();

        if (this.value.length > 0) {
            highlight(this.value);
            $("#listExcipient").show();
        } else {
            highlight('');
        }
        $("#listExcipient").show();
    });
// END Catalogue search Excipients

    var timeOutID = 0;
    var searchForm = $('form.cata_filters');
    var appendFilterTag = function (checkboxElement) {
        var facette = $(checkboxElement).attr("id");
        var facetteName = $(checkboxElement).attr("data-facette-name");

        //on peut specifier un nom manuel
        if(facetteName)
            var facetteTxt = facetteName;
        else
            var facetteTxt = $("[for=" + facette + "]").text();

        //ajout avec/sans pour excipients
        if(parseInt(facette.indexOf('excipient'))>-1)
        {
            if($('input#produit_search_excipientavecsans').val()=='true')
                var mentionAvecSans = 'Avec ';
            else
                var mentionAvecSans = 'Sans ';

            facetteTxt = mentionAvecSans + ': ' + facetteTxt;
        }

        $(".catalogue-filter-col-left").append("<div class=\"catalogue-filter-facette\" id=\"" + facette + "-tag\">" + facetteTxt + "<span class=\"ic-cross\"></span></div>");
    }

    // Search listing auto submit
    searchForm
        .on('ifChanged', 'input[type=checkbox]', function (event) {
            searchForm.submit();
        })
        .on('selectric-select', '#produit_search_tri', function (event) {
            searchForm.submit();
        })
        .on('click', '#produit_search_search', function (e) {
            searchForm.find('input[type="checkbox"]').each(function () {
                $(this).iCheck('uncheck');
            });
        });

    function closeAutocompleteList() {
        $('.autocomplete_container').animate({'height': 0}, 300);
    }

    searchForm.submit(function (e) {
        e.preventDefault();
        closeAutocompleteList();
        $("#produit_search_fulltext").blur();

        $(".catalogue-filter-facette").each(function(){
            $(this).iCheck('uncheck');
        });

        var searchUrl = routeProductListing + '?' + $(this).serialize();
        var textResultat = '';

        // Set Timeout for launch last ajax request
        if (timeOutID !== 0) clearTimeout(timeOutID);
        timeOutID = setTimeout(function () {
            $.ajax({
                dataType: "json",
                url: searchUrl,
                cache: false,
                success: function (data) {
                    window.history.pushState("", "Title", searchUrl);
                    $('.catalogue-container').html(data.html);
                    if(data.nbProducts == 1){
                        textResultat = 'résultat trouvé';
                    }else{
                        textResultat = 'résultats trouvés';
                    }
                    $('.cata_filters-results span').text(data.nbProducts+' '+ textResultat)
                }
            });
        }, 250);
    });

    // Rappel des facettes cochées au dessus du listing produits
    $('form.cata_filters input[type="checkbox"]').each(function (index, elt) {
        if (elt.checked) {
            appendFilterTag(elt);
        }
    });

    searchForm
        .on('ifChecked', function (event) {
            appendFilterTag(event.target);
        })
        .on('ifUnchecked', function (event) {
            var facette = $(event.target).attr("id");
            var facetteTxt = $("[for=" + facette + "]").text();

            $("#" + facette + "-tag").remove();
        });

    $(".catalogue-filter-col-left").on('click', function (e) {
        if ($(e.target).parent().hasClass('catalogue-filter-facette')) {
            var tagId = $(e.target).parent().attr("id").slice(0, -4);
            $("input#" + tagId).iCheck('uncheck');
        }
    });

// Graph taux de remboursement produit
    if ($('#prod-remboursement-graph').length > 0) {

        var blankPart = Math.floor(100 - tauxRemboursement);

        var myChart = new Chart($('#prod-remboursement-graph'), {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [tauxRemboursement, blankPart],
                    backgroundColor: [
                        '#782fcb',
                        '#e0cff4'
                    ],
                    borderWidth: 0,
                    hoverBackgroundColor: [
                        '#782fcb',
                        '#e0cff4'
                    ]
                }]
            },
            options: {
                cutoutPercentage: 85,
                responsive: false,
                legendCallback: function (chart) {
                    return tauxRemboursement + "%";
                },
                tooltips: {
                    enabled: false
                }
            }
        });

        var legend = myChart.generateLegend();
        document.getElementById("prod-remboursement-graph-legend").innerHTML = legend;
    }

    // Product slider
    if ($('#prod-slider-main').length > 0) {
        /* pour éviter l'affichage de toutes les images au chargement de la page : */
        $('.prod-slider img:not(.bx-clone)').show();

        productSlider = $('#prod-slider-main').bxSlider({
            pagerCustom: '#prod-slider-nav',
            mode: "horizontal",
            useCSS: true,
            adaptiveHeight: false,
            startSlide: 0,
            randomStart: false,
            preloadImages: 'all'
        });
    }

    function tog(v){return v?'addClass':'removeClass';}
    $(document).on('input', '.clearable', function(){
        $(this)[tog(this.value)]('x');
    }).on('mousemove', '.x', function( e ){
        $(this)[tog(this.offsetWidth-40 < e.clientX-this.getBoundingClientRect().left)]('onX');
    }).on('touchstart click', '.onX', function( ev ){
        ev.preventDefault();
        $(this).removeClass('x onX').val('').change();
        searchForm.submit();
    });
}

//gestion switch excipient
if($('div.switchperso') && $('input#produit_search_excipientavecsans'))
{
    $('div.switchperso').click(function() {

        var imgSwitch = $('div.switchperso img');

        var oldSrc = imgSwitch.attr("src");
        var newImg = imgSwitch.attr("data-img-toggle");
        var newValue = imgSwitch.attr("data-switch-value");

        if(newValue=='false')
            newValue = 'true';
        else
            newValue = 'false';

        //backup de l'image actuelle et remplacement
        imgSwitch.attr("src",newImg);
        imgSwitch.attr("data-img-toggle",oldSrc);
        imgSwitch.attr("data-switch-value",newValue);

        //placement new value
        $('input#produit_search_excipientavecsans').val(newValue);

        //on RAZ toute selection d'excipient, cela provoque d'ailleurs un submit
        $('div.cata_filters-excipients-listing div.icheckbox').iCheck('uncheck');

        /* je relance le submit tout de même, si on le fait pas, le dernier appel ajax risque de montrer la liste
        avec encore un excipient coché */
        searchForm.submit();
    });
}
//Decochage condDM -> decochage tout sous cond
$('input#produit_search_condDM').on('ifUnchecked', function(event){
    $('div.cata_filters-conddmdci-listing div.icheckbox').iCheck('uncheck');
    $(this).attr('data-partial',false);
});

//Cochage condDM -> cochage tout sous cond
$('input#produit_search_condDM').on('ifChecked', function(event){
    var condDM = $('input#produit_search_condDM');

    if(condDM.attr('data-partial') != 'true') {
        $('div.cata_filters-conddmdci-listing div.icheckbox').iCheck('check');
    }
});

//gestion check sous cond DM
$('div.cata_filters-conddmdci-listing div.icheckbox').on('ifChecked', function(){
    var condDM = $('input#produit_search_condDM');
    condDM.attr('data-partial',true).iCheck('check');
});

//Decochage PDA -> decochage tout sous cond
$('input#produit_search_pda').on('ifUnchecked', function(event){
    $('div.cata_filters-condpda-listing div.icheckbox').iCheck('uncheck');
    $(this).attr('data-partial',false);
});

//Cochage PDA -> cochage tout sous cond
$('input#produit_search_pda').on('ifChecked', function(event){
    var condPda = $('input#produit_search_pda');

    if(condPda.attr('data-partial') != 'true') {
        $('div.cata_filters-condpda-listing div.icheckbox').iCheck('check');
    }
});

//gestion check sous cond PDA
$('div.cata_filters-condpda-listing div.icheckbox').on('ifChecked', function(){
    var condPda = $('input#produit_search_pda');

    condPda.attr('data-partial',true).iCheck('check');
});

//Decochage Biogaran conseil -> decochage tout sous cond
$('input#produit_search_biogaranConseil').on('ifUnchecked', function(event){
    $('div.cata_filters-biogaranconseil-listing div.icheckbox').iCheck('uncheck');
    $(this).attr('data-partial',false);
});

//Cochage Biogaran conseil -> cochage tout sous cond
$('input#produit_search_biogaranConseil').on('ifChecked', function(event){
    var condBioCons = $('input#produit_search_biogaranConseil');

    if (condBioCons.attr('data-partial') != 'true') {
        $('div.cata_filters-biogaranconseil-listing div.icheckbox').iCheck('check');
    }
});

//gestion check sous cond Biogaran conseil
$('div.cata_filters-biogaranconseil-listing div.icheckbox').on('ifChecked', function(){
    var condBioCons = $('input#produit_search_biogaranConseil');

    condBioCons.attr('data-partial',true).iCheck('check');
});

//--Gestion du décochage total des biogaran conseil -> décochage biogaran conseil
$('div.cata_filters-biogaranconseil-listing div.icheckbox').on('ifUnchecked', function(){
    var checked = false;
    $('div.cata_filters-biogaranconseil-listing div.icheckbox').each(function (index, elem) {
        if ($('input', elem).prop('checked')) {
            checked = true;
        }
    });

    if (!checked) {
        $('input#produit_search_biogaranConseil').attr('data-partial', false).iCheck('uncheck');
        $('input#produit_search_biogaranConseil').prop('checked', false);
    }
});
