if ($('.openAction').length > 0) {
    $(document).on('click', '.openAction', function () {
        const cellFixed = $($(this).parent()[0]);
        cellFixed.children('.groupAction, .closeAction').show('slow');
        cellFixed.children('.normalAction').hide();
        $(this).hide();
    });
    $(document).on('click', '.closeAction', function () {
        const cellFixed = $($(this).parent()[0]);
        cellFixed.children('.normalAction, .openAction').show('slow');
        cellFixed.children('.groupAction').hide();
        $(this).hide();
    })
}

window.printLink = function (link) {
    const printWindow = window.open(link);
    printWindow.onload = () => printWindow.print();
    printWindow.onafterprint = () => printWindow.close();
};

window.mailtoLink = function (link) {
    window.open(link, '_blank');
    window.open('mailto:', '_blank');
};
