if ($('form.cata_filters').length > 0) {

    function appendAutocompleteTerm(elt, suggestions) {

        var list = $(".autocomplete_" + elt);
        list.children("dd").remove();
        if (suggestions[elt]){
            var attrElt = elt;
            $.each( suggestions[elt], function( elt, suggestion ) {
                // console.log(elt);
                // console.log(suggestion);
                list.append("<dd class='autocomplete_" + attrElt + "-term'>" + suggestion + "</dd>");
            });
            /*
             for (var suggestion of suggestions[elt]) {
                list.append("<dd class='autocomplete_" + elt + "-term'>" + suggestion + "</dd>");
            }*/
        } else {
            if (elt === "dci") {
                list.append("<dd>Pas de DCI correspondante</dd>");
            } else {
                list.append("<dd>Aucune spécialité de référence correspondante</dd>");
            }
        }
    }

    function openAutocompleteList() {
        var listHeight;

        if ($(window).width() >= 480) {
            listHeight = '180px';
        } else {
            listHeight = '340px';
        }

        $('.autocomplete_container').animate({'height': listHeight}, 300);
    }

    function closeAutocompleteList() {
        $('.autocomplete_container').animate({'height': 0}, 300);
    }

    function makeDelay(ms) {
        var timer = 0;
        return function (callback) {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    }

    var delay = makeDelay(400);

    // Fill in suggestions list :
    $("#produit_search_fulltext").on("keyup", function (e) {
        var searchField = document.getElementById('produit_search_fulltext');

        delay(function () {
            if (searchField.value.length > 2) {
                $.get(routeProductAutocomplete + "/" + searchField.value, function (suggestions) {

                    if ($(suggestions).length > 0) {
                        appendAutocompleteTerm("specialiteReference", suggestions);
                        appendAutocompleteTerm("dci", suggestions);
                        openAutocompleteList();
                    } else {
                        closeAutocompleteList();
                    }
                });
            } else {
                closeAutocompleteList();
            }
        });
    });

    // When user click on a suggestion, put it in the search field and send request
    $('.autocomplete_dci, .autocomplete_specialiteReference').on('click', function (e) {

        //reset filter
        $('input').iCheck('uncheck');

        if ($(e.target).hasClass('autocomplete_dci-term') || $(e.target).hasClass('autocomplete_specialiteReference-term')) {
            var txt = e.target.textContent;

            document.getElementById('produit_search_fulltext').value = txt;
            closeAutocompleteList();
            $('form.cata_filters').submit();
        }
    });

    // we need to close search suggestions if user click on filters or on search button
    $(".catalogue_content-leftcol input").each(function (i, elt) {
        $(elt).on('click ifChanged', function (e) {
            closeAutocompleteList();
        });
    });
    $("#produit_search_search").on('click', function () {
        closeAutocompleteList();
    });

    // loader
    $(document)
        .on("ajaxSend", function () {
            $(".product_search-loader").fadeIn(500);
        })
        .on("ajaxComplete", function () {
            $(".product_search-loader").fadeOut(500);
        });
}
