(function () {
    this.G361CookieConsents = function () {

        var _self = this;

        var defaults = {
            gtm: false,                         // {Boolean}    show / hide in modal  :: Default to FALSE (GTM manage all script)
            ga: true,                           // {Boolean}    show / hide in modal
            tw_adv: true,                       // {Boolean}    show / hide in modal
            twitter: true,                      // {Boolean}    show / hide in modal
            fb_connect: true,                   // {Boolean}    show / hide in modal
            fb_pixel: true,                     // {Boolean}    show / hide in modal
            youtube: true,                      // {Boolean}    show / hide in modal
            ga_manage_by_gtm: false,            // {Boolean}    Push variable in GTM or include script for Google Analytics
        }

        // Create options by extending defaults with the passed in arugments
        if (arguments[0] && typeof arguments[0] === "object") {
            this.options = extendDefaults(defaults, arguments[0]);
        }

        // If cookie consentment not exist
        if (!this.g361_cc_getCookie('g361_cc_optout')) {
            this.g361_cc_setCookie('g361_cc_optout', 'ga, yt');
        }

        // Set value of checkbox Button
        this.g361_cc_SetRadioButton();

        // Scan the cooki consentment for desactivate module
        this.g361_cc_ScanCookieOptOut();

        // Lanch stop youtube video if youte video is present in current page
        if (this.options.youtube) this.g361_cc_Youtube();

        $('.g361ccga .icheckbox input').on('ifChecked', function (event) {
            var g361CcOptOut = _self.g361_cc_getCookie('g361_cc_optout');
            if(/yt/.test(g361CcOptOut)){
                _self.g361_cc_setCookie('g361_cc_optout', 'ga, yt');
            }
            else{
                _self.g361_cc_setCookie('g361_cc_optout', 'ga');
            }
            _self.g361_cc_ScanCookieOptOut();
        });

        $('.g361ccyt .icheckbox input').on('ifChecked', function (event) {
            var g361CcOptOut = _self.g361_cc_getCookie('g361_cc_optout');
            if (/ga/.test(g361CcOptOut)){
                _self.g361_cc_setCookie('g361_cc_optout', 'ga, yt');
            }
            else{
                _self.g361_cc_setCookie('g361_cc_optout', 'yt');
            }
            _self.g361_cc_ScanCookieOptOut();
        });

        $('.g361ccga .icheckbox input').on('ifUnchecked', function (event) {
            var g361CcOptOut = _self.g361_cc_getCookie('g361_cc_optout');
            if (/yt/.test(g361CcOptOut)){
                _self.g361_cc_setCookie('g361_cc_optout', 'yt');
            }
            else{
                _self.g361_cc_setCookie('g361_cc_optout', 'deny_all');
            }
            _self.g361_cc_ScanCookieOptOut();
        });

        $('.g361ccyt .icheckbox input').on('ifUnchecked', function (event) {
            var g361CcOptOut = _self.g361_cc_getCookie('g361_cc_optout');
            if (/ga/.test(g361CcOptOut)){
                _self.g361_cc_setCookie('g361_cc_optout', 'ga');
            }
            else{
                _self.g361_cc_setCookie('g361_cc_optout', 'deny_all');
            }
            _self.g361_cc_ScanCookieOptOut();
        });

    }

    /**
     * PUBLIC METHOD
     * Create Cookie for the website
     * @param {String} cname    Name of cookie
     * @return {String}         Value of cookie name
     */
    G361CookieConsents.prototype.g361_cc_getCookie = function (cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');

        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }

        return "";
    }

    /**
     * PUBLIC METHOD
     * Create Cookie for the website
     * @param {String} name             Name of cookie
     * @param {Boolean} cookieConsent   Boolean for set specifique cookie consentment
     * @param {String} value            Value of cookie
     */
    G361CookieConsents.prototype.g361_cc_setCookie = function (name, value) {
        // Time +/- 1 an : 60 secondes * 60 minutes * 24 heures * 365 jours
        var expire_secondes = 60 * 60 * 24 * 365;

        // Get the hostname
        var hostname = window.location.hostname;
        var regex = /www/gi;
        hostname = hostname.replace(regex, '');

        // Set specifique cookie for consentment
        //if (cookieConsent) value = g361_cc_SetCookieConsentment();

        //Création du cookie
        var d = new Date();
        d.setTime(d.getTime() + expire_secondes * 1000);
        d.toUTCString();
        document.cookie = name + "=" + (value || "") + ";expires=" + d + ";domain=" + hostname + ";path=/";
    }

    /**
     * PUBLIC METHOD
     * Detect cookie youtube consent and replace youtube iframe by content to accept cookie youtube consent
     */
    G361CookieConsents.prototype.g361_cc_Youtube = function() {
        var g361CcOptOut = this.g361_cc_getCookie('g361_cc_optout');

        // Tet if youtube consent is accepted in cookie g361_cc_optout
        if (!(/yt/.test(g361CcOptOut)) && this.options.youtube) {
            // Create div fov replace iframe youtube
            var g361CcDiv_yt = document.createElement("div");
            g361CcDiv_yt.setAttribute("id", "g361-cc-youtube-optout");
            var g361CcDiv_yt_content = "<div class='g361-cc-youtube-content'><b>YouTube est désactivé.</b><br/>Autorisez le dépôt de cookies pour accéder au contenu.<br/>";
            g361CcDiv_yt_content += "<a class='g361-cc-cta-yt-accept' href='#' data-g361-cc-optout='yt'>Autoriser</a>";
            var el_yt = document.querySelectorAll("iframe[src*='youtube']");
            for (var i = 0; i < el_yt.length; i++) {
                el_yt[i].parentNode.replaceChild(g361CcDiv_yt, el_yt[i]);
            }
            var el_yt_consent = document.querySelectorAll("#g361-cc-youtube-optout");
            for (var i = 0; i < el_yt_consent.length; i++) {
                el_yt_consent[i].innerHTML = g361CcDiv_yt_content;
                var height = el_yt_consent[i].clientWidth / 1.78;
                el_yt_consent[i].style.height = height + "px";
            }

            // Add event on button to accept youtube consent
            this.g361_cc_YoutubeEnabled();
        }
    }

    /**
     * PUBLIC METHOD
     * Event on click button to accept youtube video
     */
    G361CookieConsents.prototype.g361_cc_YoutubeEnabled = function() {
        var _self = this;
        var el_consent_cta_yt = document.querySelectorAll("#g361-cc-youtube-optout .g361-cc-cta-yt-accept");
        for (var i = 0; i < el_consent_cta_yt.length; i++) {
            // Click on accept cookie
            el_consent_cta_yt[i].onclick = function (event) {
                event.preventDefault();
                var el_consent_cta_attribute = event.target.getAttribute("data-g361-cc-optout");
                if (el_consent_cta_attribute == "yt") {
                    // Update cookie consent
                    var g361CcOptOut = _self.g361_cc_getCookie('g361_cc_optout');
                    if (/ga/.test(g361CcOptOut)) {
                        _self.g361_cc_setCookie('g361_cc_optout', 'ga, yt');
                    }
                    else {
                        _self.g361_cc_setCookie('g361_cc_optout', 'yt');
                    }
                    location.reload();
                }
            }
        }
    }

    /**
     * PUBLIC METHOD
     * Set radio button in modal by cookie consents
     */
    G361CookieConsents.prototype.g361_cc_SetRadioButton = function() {
        var g361CcOptOut = this.g361_cc_getCookie('g361_cc_optout');

        if (jQuery('body.profil').length > 0) {
            if (/ga/.test(g361CcOptOut)) {
                jQuery('.g361ccga .icheckbox').iCheck('check');
            }
            if (/yt/.test(g361CcOptOut)) {
                jQuery('.g361ccyt .icheckbox').iCheck('check');
            }
        }
    }

    /**
     * PUBLIC METHOD
     * Scan cookie g361_cc_optout and launch callback for install script authorized
     */
    G361CookieConsents.prototype.g361_cc_ScanCookieOptOut = function() {
        var g361CcOptOut = this.g361_cc_getCookie('g361_cc_optout');
        var g361CcOptOutSplit = g361CcOptOut.split(",");
        var _self = this;

        for (var j = 0; j < g361CcOptOutSplit.length; j++) {
            _self.g361_cc_CallbackFunctions(g361CcOptOutSplit[j]);
        }
    }

    /**
     * PUBLIC METHOD
     * Activate the services via add script in DOM or push event in GTM
     * @param {String} cookieconsent    Cookie authorized by the application
     */
    G361CookieConsents.prototype.g361_cc_CallbackFunctions = function (cookieConsent) {
        if (cookieConsent == 'ga') {
            if (this.options.ga_manage_by_gtm) {
                // If Google Analytics is managed by Google Tag Manager : push event
                window.dataLayer.push({
                    'event': 'g361_cc_google_analytics'
                });
            }
            else if (!this.options.ga_manage_by_gtm && this.options.ga_uaid != '') {
                // Else include script Google Analytics
                var ga_script = document.createElement('script');
                ga_script.type = 'text/javascript';
                var ga_script_content = "(function (i, s, o, g, r, a, m) {i['GoogleAnalyticsObject'] = r;i[r] = i[r] || function () {(i[r].q = i[r].q || []).push(arguments)}, i[r].l = 1 * new Date();a = s.createElement(o),m = s.getElementsByTagName(o)[0];a.async = 1;a.src = g;m.parentNode.insertBefore(a, m)})(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');";
                ga_script_content += "ga('create', '" + this.options.ga_uaid + "', 'auto');"
                ga_script_content += "ga('send', 'pageview');"
                ga_script.innerHTML = ga_script_content;
                document.getElementsByTagName('head')[0].appendChild(ga_script);
            }
        }
    }

    /**
     * PRIVATE METHOD
     * Set value for cookie consentment
     * @return {String} Values of check in modal box cookie consent
     */
    function g361_cc_SetCookieConsentment() {
        var cookie_consent_value = [];
        var el = document.querySelectorAll(".g361-cc-content .g361-cc-radio input[type=radio].enabled");

        for (var i = 0; i < el.length; i++) {
            if (el[i].checked) {
                cookie_consent_value[i] = el[i].getAttribute("data-g361-cc-optout");
            }
        }

        var cookie_consent_value_clean = cookie_consent_value.filter(function (val) {
            return val !== "";
        });

        if (cookie_consent_value_clean.length < 1) {
            cookie_consent_value_clean[0] = "deny_all";
        }

        return cookie_consent_value_clean;
    }

    /**
     * PRIVATE METHOD
     * Extend options from defaults variable
     * @param {Array} source        The default options
     * @param {Array} properties    Options for override default options
     * @return {Array}              Array of options
     */
    function extendDefaults(source, properties) {
        var property;
        for (property in properties) {
            if (properties.hasOwnProperty(property)) {
                source[property] = properties[property];
            }
        }
        return source;
    }
}());

var g361cc = '';
// window.addEventListener("load", function (event) {
jQuery(document).ready(function () {
    g361cc = new G361CookieConsents({
        // Active or desactive in modal / cookies consents
        gtm: false,
        ga: true,
        tw_adv: false,
        twitter: false,
        fb_connect: false,
        fb_pixel: false,
        youtube: true,

        // Push event in GTM or include script
        ga_manage_by_gtm: true
    });

    jQuery(document).on('lity:ready', function (event, instance) {
        if (!(/yt/.test(g361cc.g361_cc_getCookie('g361_cc_optout')))) {
            g361cc.g361_cc_Youtube();
        }
    });

});
