require('lity')
function getDateWithoutTimezone(date) {
    const tzoffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
    return (new Date(date - tzoffset)).toISOString().slice(0, -1);
}

$(() =>{
    $(document).on('lity:open', (e) =>{
        $('.lity-wrap').removeAttr('data-lity-close');
    })
});

$(() => {
    const dateBeginHidden = $($("input[id*='_dateBegin']:hidden")[0]);
    const dateEndHidden = $($("input[id*='_dateEnd']:hidden")[0]);
    const dateSelectorOptions = {language: 'fr'};
    let dateBegin; let dateEnd = '';
    const selectDateBegin = $($('.datebegin')[0]);
    const selectDateEnd = $($('.dateend')[0]);
    const dateBeginShow = $('#dateBeginShow');
    const dateEndShow = $('#dateEndShow');
    selectDateBegin.datepicker(dateSelectorOptions)
        .on('changeDate', (e) => {
            const savedate = selectDateEnd.datepicker('getDate');
            selectDateEnd.datepicker('setStartDate', e.date);
            selectDateEnd.datepicker('setDate', savedate);
        });
    selectDateEnd.datepicker(dateSelectorOptions);
    if (dateBeginHidden.val() != '' && dateEndHidden.val() != '') {
        dateBegin = new Date(dateBeginHidden.val());
        dateEnd = new Date(dateEndHidden.val());
        selectDateBegin.datepicker('setDate', dateBegin);
        selectDateEnd.datepicker('setDate', dateEnd);
        dateBeginShow.text(dateBegin.toLocaleDateString('fr'));
        dateEndShow.text(dateEnd.toLocaleDateString('fr'));
    } else {
        const dateNow = new Date();
        const firstDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
        selectDateBegin.datepicker('setDate', firstDay);
        selectDateEnd.datepicker('setDate', dateNow);
    }
    $('#filterSubmit').on('click', function() {
        $($(this).parents().find('form.filters-form')[0]).attr('novalidate', 'novalidate');
        $($(this).parents().find('form.filters-form')[0]).submit();
    });
    $('#filterClean').on('click', function() {
        dateBeginShow.text('A définir');
        dateEndShow.text('A définir');
        $($(this).parents().find('form.filters-form')[0]).find('input, select').each((index, elem) => {
            if (elem.id !== 'order_filters_section' && !elem.id.includes('token')) {
                $(elem).val('');
            }
        });
        $($(this).parents().find('form.filters-form')[0]).submit();
    });
    $('#validDate').on('click', () => {
        const getBeginDate = selectDateBegin.datepicker('getDate');
        const getEndDate = selectDateEnd.datepicker('getDate');
        if (getBeginDate && getEndDate) {
            dateBeginShow.text(getBeginDate.toLocaleDateString('fr'));
            dateEndShow.text(getEndDate.toLocaleDateString('fr'));
            dateBeginHidden.val(getDateWithoutTimezone(getBeginDate).split('T')[0]);
            dateEndHidden.val(getDateWithoutTimezone(getEndDate).split('T')[0]);
        }
    });

    // NOT USED ANYMORE
    // $('.tooltip_date').tooltipster({
    //     functionInit (instance, helper) {
    //         const content = $(helper.origin).find('.tooltip_date_content').detach();
    //         instance.content(content);
    //     },
    //     trigger: 'click',
    //     animation: 'fade',
    //     position: 'bottom',
    //     autoClose: false
    // });
})
// Profile mobile
if ($('.hdr-profile_toggle').length > 0) {
    $('.hdr-profile_toggle').on('click', () => {
        $('.hdr-user_box').addClass('hdr-user_box-mobile-open');
    });
    $('.logout-mobile-header-ic').on('click', () => {
        $('.hdr-user_box').removeClass('hdr-user_box-mobile-open');
    });
}


function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

if ($('.header-order-forms').length > 0) {
    fetch($('.header-order-forms').data('api-url'), {
        method: 'POST',
        headers: {
            "Authorization": getCookie('jwtToken'),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: `
                query getOfficineOrderFormsCount {
                  getOfficineOrderFormsCount
                }
            `,
        }),
    })
    .then((response) => {
        return response.json()
    })
    .then((json) => {
        if (json.data.getOfficineOrderFormsCount > 0) {
            $('#js-order-forms-count').html(json.data.getOfficineOrderFormsCount)
            $('#js-order-forms-count').removeClass('header-order-forms-badge--hidden')
        }
    })
}

// Style checkboxes
$('input:not([name="simotc-remise-radio"], #ospharm-share-checkbox, #ospharm-member-checkbox)').iCheck();

// BANDEAU MAJ DATA GROUPEMENT
if ($('.flash-groupement-litige').length > 0) {
    $(".flash-groupement-litige-content").slideToggle();
    $(".flash-groupement-litige-content").css("display", "flex");
}

// Styles select
if ($('select:not(#simotc-product-select, #simotc-calculator-compare-select, .no-selectric)').length > 0 /* && typeof input_label != 'undefined' */) {
    // exception hauteur pour page mon profil
    if ($('.litigeForm').length) {
        $('select:not(#simotc-product-select, #simotc-calculator-compare-select, .no-selectric)').selectric({
            maxHeight: 200,
            labelBuilder (currItem) {
                return currItem.index > 0 ? `<span>${  currItem.text  }</span>` : currItem.text;
            }
        });
    } else {
        $('select:not(#simotc-product-select, #simotc-calculator-compare-select, #contrat_listing_filters_contratState, #contrat_listing_filters_contratType, #orders-filters-form * select, #facture_listing_filters * select, .no-selectric)').selectric({
            labelBuilder (currItem) {
                return currItem.index > 0 ? `<span>${  currItem.text  }</span>` : currItem.text;
            }
        });
    }

}

if ($('.hdr-usr_box-listofficines').length > 0) {
    $('.hdr-usr_box-listofficines').selectric({
        optionsItemBuilder: '<span class="ic-city selectric-leftcol"></span><span class="selectric-rightcol">{text}</span>',
        labelBuilder: '<span class="ic-city"></span> {text}',
    });
}

if ($('.box-listofficines').length > 0) {
    $('.box-listofficines').selectric({
        optionsItemBuilder: '<span class="ic-city selectric-leftcol"></span><span class="selectric-rightcol">{text}</span>',
        labelBuilder: '<span class="ic-city"></span> {text}',
    });
}

if ($('#appbundle_pharmacienequipemembre_currentOfficine').length > 0) {
    $('#appbundle_pharmacienequipemembre_currentOfficine').selectric({
        optionsItemBuilder: '<span class="ic-city selectric-leftcol"></span><span class="selectric-rightcol">{text}</span>',
        labelBuilder: '<span class="ic-city"></span> {text}',
    });
}

// Prehome mobile
if ($('.ph-connexion_link-mobile').length > 0) {
    $('.ph-connexion_link-mobile').on('click', () => {
        $('body').animate({'scrollTop': $('#mobile-form').offset().top});
    });

    if ($('.flash-error').length > 0) {
        $('body').animate({'scrollTop': $('.flash-msg').offset().top}, 700);
    }
}

$(".hdr-usr_box-listofficines").change(function () {
    $(this).closest(".formSelectOfficine").submit();
});
$(".header-officineSelector").change(function () {
    $(this).closest(".formSelectOfficine").submit();
});

$('.header-mobile-officine-item').on('click', (e) => {
    let $target = $(e.target)
    if (!$target.hasClass('header-mobile-officine-item')) {
        $target = $target.parent()
    }

    $(".header-officineSelector").val($target.data('officine-nrid'))
    $(".formSelectOfficine").submit();
})

$(".box-listofficines").change(() => {
    $(".formSelectOfficine").submit();
});
// Menu Services patients
if ($('.servicespatients-menu').length > 0) {

    if ($(window).width() > 767) {
        $(".servicespatients-leftcol").stick_in_parent();
    }

    $('.servicespatients-menu a').on('click', function (e) {
        e.preventDefault();
        const target = $(this).attr('href');

        $('body, html').animate({'scrollTop': $(target).offset().top});
    });

    $('select:not(#simotc-product-select, #simotc-calculator-compare-select, .no-selectric)').selectric();
    $('#servicespatients-menu-mobile').on('change', () => {
        const scrollTo = $('#servicespatients-menu-mobile').val();
        $('body, html').animate({'scrollTop': $(`#${  scrollTo}`).offset().top});
    });

}

// Officine toggle sur page mon profil
if ($('.profile-block-officines-toggle').length > 0) {

    let baseBlockHeight; let collapsedBlockHeight; let clickedBlockParent;


    $('.profile-block-officines-detail').not('.open').find('.profile-block-officines-toggle').each((i, elt) => {
        const $elt = $(elt);
        // console.log($elt.text());
        baseBlockHeight = $elt.parent('.profile-block-officines-wrapper').innerHeight();
        collapsedBlockHeight = $elt.find('.pb-officines-header-txt').innerHeight() + 20;


        $elt.parents('.profile-block-officines-detail').css({'height': collapsedBlockHeight});
        if ($elt.next('.hc-block-content').children('.field-error').length > 0) {
            $elt.parents('.profile-block-officines-detail').animate({'height': `${baseBlockHeight  }px`}, 'slow').addClass('open');
        }
    });

    $('.profile-block-officines-toggle').on('click', function () {
        baseBlockHeight = $(this).parent('.profile-block-officines-wrapper').innerHeight();
        collapsedBlockHeight = $(this).find('.pb-officines-header-txt').innerHeight() + 20;
        clickedBlockParent = $(this).parents('.profile-block-officines-detail');

        if (clickedBlockParent.innerHeight() < 100) {
            clickedBlockParent.animate({'height': `${baseBlockHeight  }px`}, 'slow').addClass("open");
        } else {
            clickedBlockParent.animate({'height': `${collapsedBlockHeight  }px`}, 'slow').removeClass("open");
        }
    });
}

// Autosubmit forms
if ($('.autosubmit').length > 0) {
    $('.autosubmit select').on('change', () => {
        $('.autosubmit').submit();
    });
}

// Responsive tables
if ($('.editorial-content table').length > 0) {
    $('.editorial-content table').wrap('<div class="table-responsive"></div>');
}

// Tooltip
// NOT USED ANYMORE
// if ($('.tooltip_toggle').length > 0) {
//     $('.tooltip_toggle').tooltipster({
//         functionInit (instance, helper) {
//             const content = $(helper.origin).find('.tooltip_content').detach();
//             instance.content(content);
//         },
//         trigger: 'click',
//         animation: 'fade'
//     });
// }


// Blocs PMP
if ($('.bloc-pmp-contrat').length > 0 && $('.bloc-pmp-contrat').css('float') === 'left') {

    $('.bloc-pmp-contrat-left').on('click', function () {
        $(this).next('.bloc-pmp-mobile').toggleClass('active');
        $(this).children('.bloc-pmp-close').toggleClass('active');
        $('.bloc-pmp-contrat-right, .bloc-pmp-mobile-right').toggleClass('masked');
    });
    $('.bloc-pmp-contrat-right').on('click', function () {
        $(this).next('.bloc-pmp-mobile').toggleClass('active');
        $(this).children('.bloc-pmp-close').toggleClass('active');
        $('.bloc-pmp-contrat-left, .bloc-pmp-mobile-left').toggleClass('masked');
    });
}


// Dashboard airport effect
if ($(".pharmacien-dashboard").length > 0) {
    // $(".previousYear").hide();

    const datapath = $('.pharmacien-dashboard .dashboard-content').attr('data-path');
    $('#counter-anim-rembourse').splitFlap({
        image: `${datapath  }/img/dashboard/airport_green.png`,
        charsMap: '0123456789€',
        charWidth: 35,
        charHeight: 47,
        textInit: "0"
    });

    $('#counter-anim-nonrembourse').splitFlap({
        image: `${datapath  }/img/dashboard/airport_purple.png`,
        charsMap: '0123456789€',
        charWidth: 35,
        charHeight: 47,
        textInit: "0"
    });

    $(".showRdpYearDetails").click(() => {
        const previousYear = $(".previousYearLabel").html();
        const currentYear = $(".mainYear").html();
        $(".previousYearLabel").html(currentYear);
        $(".mainYear").html(previousYear);
        $(".previousYear").toggleClass('isVisible');
        $(".currentYear").toggle();
        $(".showPrevious").toggle();
        $(".showCurrent").toggle();
    });

    $("#deploy-rdp").click((e) => {
        e.preventDefault();
        $.ajax({
            method: "POST",
            url: "indicateurs-de-performance/specialite/this-year",
            dataType: 'html',
            success (code_html, statut) {
                $("#deployed-rdp").html(code_html);
            }
        });
        $('.dashboard-content-bottom').toggle();
        const scrollTo = $('#undeployed-rdp').offset().top - 100;
        $('body, html').animate({'scrollTop': scrollTo}, 500);
        $('#undeployed-rdp').slideToggle("slow", () => {
            $('#deployed-rdp').slideToggle("slow");
            $(".dashboard-content-top").css('padding-top', '30px');
        });

    });


    $(document).on('click', ".deploy-rdp-toggle", function (e) {
        e.preventDefault();
        const dataRequest = $(this).attr("data-toggle");
        $.ajax({
            method: "POST",
            url: `indicateurs-de-performance/specialite/${  dataRequest}`,
            dataType: 'html',
            success (code_html, statut) {
                $("#deployed-rdp").html(code_html);
            }
        });
    });

    $(document).on('click', ".close-deployed", (e) => {
        e.preventDefault();
        $('#deployed-rdp').slideToggle("slow", () => {
            $('#undeployed-rdp').slideToggle("slow");
            $('.dashboard-content-bottom').toggle();
            $(".dashboard-content-top").css('padding-top', '100px');
        });

    });
}

$.fn.preventDoubleSubmission = function () {
    $(this).on('submit', function (e) {
        const $form = $(this);
        if ($form.data('submitted') === true) {
            // Previously submitted - don't submit again
            e.preventDefault();
        } else {
            // Mark it so that the next submit can be ignored
            $form.data('submitted', true);
        }
    });
    // Keep chainability
    return this;
};

if ($(".login").length > 0) {
    $('#anonymousFormContact').preventDoubleSubmission();
}
if ($(".contact_connecte").length > 0) {
    $('#loggedInFormContact').preventDoubleSubmission();
}
// Déploiement du tableau RDP
// if ($('#deployed-rdp').length) {
//     $('#deploy-rdp').on('click', function (e) {
//
//         e.preventDefault();
//         var scrollTo = $('#undeployed-rdp').offset().top - 100;
//         $('body, html').animate({'scrollTop': scrollTo}, 500);
//         $('#undeployed-rdp').slideToggle("slow", function () {
//             $('#deployed-rdp').slideToggle("slow");
//         });
//
//     });
//     $('#close-deployed').on('click', function (e) {
//
//         e.preventDefault();
//         $('#deployed-rdp').slideToggle("slow", function () {
//             $('#undeployed-rdp').slideToggle("slow");
//         });
//
//     });
// }

/** * PASSWORD VALIDATOR HELP ** */
if ($(".profil").length > 0 || $(".login").length > 0) {


    $('#pharmacien_profil_user_email').keyup(function () {
        if ($(".profil").length > 0) {
            const val = $(this).val();
            if (/^([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9,!\#\$%&'\*\+\/=\?\^_`\{\|\}~-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*@([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z0-9-]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*\.(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]){2,})$/i.test(val)) {
                $('#validation-email').hide();
            } else {
                $('#validation-email').show();
            }
        }
    }).focus(() => {
        $('#validation-email').hide();
    }).blur(() => {
        $('#validation-email').hide();
    });

    $('#pharmacien_profil_user_phone').keyup(function () {
        if ($(".profil").length > 0) {
            const val = $(this).val();
            if (val.length >= 9 && val.length <= 13) {
                if (/^[0-9]+$/.test(val)) {
                    $('#validation-phone').hide();
                } else {
                    $('#validation-phone').show();
                }
            } else {
                $('#validation-phone').show();
            }
        }
    }).focus(() => {
        $('#validation-phone').hide();
    }).blur(() => {
        $('#validation-phone').hide();
    });

    $('input[type=password]').keyup(function () {

        /** * PAGE PROFIL ** */
        if ($(".profil").length > 0) {
            if ($(this).attr("id") == 'pharmacien_profil_user_plainPassword_first') {
                var topBox = 40;
            } else {
                var topBox = 80;
            }
            $('#pswd_info').css("top", topBox);
        }
        /** * PAGES RESET PASSWORD ** */
        if ($(".login").length > 0) {
            const heightInput = $(this).offset();
            let heightBox = heightInput.top + 30;
            if ($(window).width() < 768) {
                heightBox += 10;
            }
            const widthBox = heightInput.width;
            $('#pswd_info').css("top", heightBox);
            $('#pswd_info').css("width", widthBox);
        }

        $('#pswd_info').show();

        const pswd = $(this).val();
        if (pswd.length < 8) {
            $('#length').removeClass('valid').addClass('invalid');
        } else {
            $('#length').removeClass('invalid').addClass('valid');
        }
        // validate letter
        if (pswd.match(/[A-z]/)) {
            $('#letter').removeClass('invalid').addClass('valid');
        } else {
            $('#letter').removeClass('valid').addClass('invalid');
        }

        // validate capital letter
        if (pswd.match(/[A-Z]/)) {
            $('#capital').removeClass('invalid').addClass('valid');
        } else {
            $('#capital').removeClass('valid').addClass('invalid');
        }

        // validate number
        if (pswd.match(/\d/)) {
            $('#number').removeClass('invalid').addClass('valid');
        } else {
            $('#number').removeClass('valid').addClass('invalid');
        }


    }).focus(() => {
        $('#pswd_info').hide();
    }).blur(() => {
        $('#pswd_info').hide();
    });

    // data lity popin
    if ($(".profil-popup-litige").length > 0) {
        const lightbox = lity('.profil-popup-litige');
        $(".close-popin").click(() => {
            lightbox.close();
        });
    }
}

/** * SCROLL TO TOP DESKTOP ** */
$(window).scroll(function () {
    if ($(this).scrollTop() >= 100 && $(this).width() > 767) {        // If page is scrolled more than 50px
        $('#return-to-top').fadeIn(200);    // Fade in the arrow
    } else {
        $('#return-to-top').fadeOut(200);   // Else fade out the arrow
    }
});
$('#return-to-top').click(() => {      // When arrow is clicked
    $('body,html').animate({
        scrollTop: 0                       // Scroll to top of body
    }, 500);
});

// popin if pdfGenerated
if ($('div#pdfReadyPopin').length) {
    lity('div#pdfReadyPopin');
}

if ($('.header-account').length > 0) {
    const hiddenClass = 'header-menu--hidden'
    $('.header-account').on('click', () => {
        const headerMenu = $('.header-menu')
        if(headerMenu.hasClass(hiddenClass)) {
            headerMenu.removeClass(hiddenClass)
            setTimeout(function(){
                attachOutsideClickListener()
            }, 200);

        }
    })
}

function attachOutsideClickListener() {
    const hiddenClass = 'header-menu--hidden'
    $('body').on('click', () => {
        $('.header-menu').addClass(hiddenClass)
        $('body').prop('onclick', null).off('click')
    })
}


if ($('.header-mobile-officine').length > 0) {
    $('.header-mobile-officine').on('click', () => {
        $('.header-mobile-officine-container').addClass('header-mobile-officine-container--displayed')
        setTimeout(() => {
            attachMobileOfficineSelectorCloseListener()
        }, 300)
    })
}

function attachMobileOfficineSelectorCloseListener() {
    $('.header-mobile-officine-close').on('click', () => {
        $('.header-mobile-officine-container').removeClass('header-mobile-officine-container--displayed')
    })
}

if ($('.header-mobile-account').length > 0) {
    $('.header-mobile-account').on('click', () => {
        $('.header-mobile-account-container').toggleClass('header-mobile-account-container--hidden')
    })
}

if ($('#pharmacien_profil_user_plainPassword_first').length > 0) {
    $('#pharmacien_profil_user_plainPassword_first').on('change', (event) => {
        console.log('password change !')
        console.log(event.target.value)
        if (event.target.value !== '') {
            $('#pharmacien_profil_user_plainPassword_second').prop('required',true)
        } else {
            $('#pharmacien_profil_user_plainPassword_second').prop('required',false)
        }
    })
}
