/* Menu pharmacien */
if ($(".main_menu").length > 0) {
    var currentLocation = $(location).attr("pathname");

    if ($(".mm-root-label").next(".mm-ssmenu").find(".current")) {
        var $this = $(".current_ancestor");
        var $linkInside = $this.children(".mm-root-label");
        var $ssMenu = $this.children(".mm-ssmenu");

        $linkInside.addClass("mm-root-open");
        $ssMenu.addClass("mm-ssmenu-open");
    }

    $(".mm-root-link, .mm-root-label").on("click", function (e) {
        if ($(this).next(".mm-ssmenu").length > 0) {
            e.preventDefault();
            var $this = $(this);
            var $linkInside = $this.children(".mm-root-label");
            var $ssMenu = $this.next(".mm-ssmenu");

            if ($(this).hasClass("mm-root-open")) {
                $(this).removeClass("mm-root-open");
            } else {
                $(this).addClass("mm-root-open");
            }

            $(".mm-root-label")
                .not($this)
                .each(function () {
                    if ($(this).hasClass("mm-root-open")) {
                        $(this).removeClass("mm-root-open");
                        $(this)
                            .next(".mm-ssmenu")
                            .removeClass("mm-ssmenu-open");
                    }
                });
            e.stopPropagation();
            $linkInside.toggleClass("mm-root-open");
            $ssMenu.toggleClass("mm-ssmenu-open");
        }
    });

    $(".mm-ssmenu").on("click", function (e) {
        e.stopPropagation();
    });

    if (currentLocation === "/contact") {
        $(".mm-contact").find(".mm-root-link").addClass("current");
    } else {
        $(".mm-contact").find(".mm-root-link").removeClass("current");
    }

    // Mobile menu
    $(".hdr-menu_toggle").on("click", function () {
        if ($(".main_menu").hasClass("mm-open")) {
            $(".main_menu").removeClass("mm-open");
            $(".mm-root-label").removeClass("mm-root-open");
            $(".mm-overlay").hide();
        } else {
            $(".main_menu").addClass("mm-open");
            $(".mm-overlay").show();
        }
        $(".header-menu-toggle--open").toggleClass(
            "header-menu-toggle--hidden"
        );
        $(".header-menu-toggle--close").toggleClass(
            "header-menu-toggle--hidden"
        );
    });
    $(".mm-overlay").on("click", function () {
        $(".main_menu").removeClass("mm-open");
        $(".mm-overlay").hide();
        $(".mm-root-label").removeClass("mm-root-open");
    });
}
