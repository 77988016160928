jQuery(document).ready(function ($) {

    //Ajout attribut required sur le 1er input mail
    if($("#formAddPharmacienMembre").length){
        $('input#appbundle_pharmacienequipemembre_emails_0_email').attr('required', 'required');
    }

    var Utils = (function () {
        var _Utils = function () {
            var self = this;

            // https://gist.github.com/jimeh/332765
            this.mustache = function (string, data) {
                if (typeof(string) === "string" && typeof(data) === "object") {
                    for (var key in data) {
                        string = string.replace(new RegExp("{{\\s*" + key + "\\s*}}", "g"), data[key]);
                    }
                }
                return string;
            };

            this.underscore = function (string, data) {
                if (typeof(string) === "string" && typeof(data) === "object") {
                    for (var key in data) {
                        string = string.replace(new RegExp("__\\s*" + key + "\\s*__", "g"), data[key]);
                    }
                }
                return string;
            };

            this.arrayToJson = function (_array) {
                var _json = {};
                for (var i = 0; i < _array.length; i++) {
                    _json[_array[i]['name']] = _array[i]['value'];
                }
                return _json;
            };
        };

        return new _Utils();
    })();

    function addFieldOnPress(elt)
    {
        //on check son parent
        var $divContainerEmail = $(elt).parents()[2];
        var $divContainerNext = $($divContainerEmail).next();
        //on n'ajoute que si un champ email n'existe pas déjà
        if(!$($divContainerNext).hasClass('popin-emailinvite')) {
            $('.addEmailMembreCollection').trigger('click');
        }
    }

    $('form#formAddPharmacienMembre').on('submit', function (event) {
        event.preventDefault();

        //suppression champs vides
        $('form#formAddPharmacienMembre input[type="email"]').each(function(index,elt) {
            if(elt.value.length == 0)
            {
                var eltDivToDelete = $(elt).parents()[2];
                $(eltDivToDelete).remove();
            }
        });

        var $submitBtn = $(this).find('button[type=submit]').eq(0);
        $submitBtn.attr('disabled', true);
        var data = $(this).serializeArray();

        console.log("data", data);

        $.post(this.action, data)
            .done(function (data) {
                console.log(data);
                if (data['status'] === false) {
                    alert(data['message']);
                    $($submitBtn).attr('disabled', false);
                    return;
                }

                window.location.assign(data['url']);
            })
            .fail(function (result) {
                console.log(result);
                alert("Une erreur est survenue lors du traitement. Veuillez contacter l'administrateur.");
                $($submitBtn).attr('disabled', false);
            });
    });

    $('.addEmailMembreCollection').click(function (e) {
        e.preventDefault();

        var list = $($(this).attr('data-list'));

        // Try to find the counter of the list
        var counter = list.data('widget-counter') | list.children().length;
        // If the counter does not exist, use the length of the list
        if (!counter) {
            counter = list.children().length;
        }

        // grab the prototype template
        var newWidget = list.attr('data-prototype');
        // replace the "__name__" used in the id and name of the prototype
        // with a number that's unique to your emails
        // end name attribute looks like name="contact[emails][2]"
        newWidget = newWidget.replace(/__name__/g, counter);
        // Increase the counter
        counter++;
        // And store it, the length cannot be used if deleting widgets is allowed
        list.data(' widget-counter', counter);

        // create a new list element and add it to the list
        var newElem = $(list.attr('data-widget-tags')).html(newWidget + '<label for="username1" class="text-hide ic-mail3">E-mail</label>');
        newElem.appendTo(list);

        $('form#formAddPharmacienMembre input[type="email"]').off('keypress');

        $('form#formAddPharmacienMembre input[type="email"]').keypress(function() {
            addFieldOnPress(this);
        });
    });

    //Ajout nouveau champ sur remplissage
    $('form#formAddPharmacienMembre input[type="email"]').keypress(function() {
        addFieldOnPress(this);
    });
});
