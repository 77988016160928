if ($('#blockListOpenables').length) {

    function showElement(index,target)
    {
        var target = 'div#blockListOpenables div.rdp-fact-selections div';
        var imgTarget = 'div#blockListOpenablesLinks svg image';
        var $divCollection = $(target);
        var $imgCollection = $(imgTarget);


        $divCollection.addClass('hide');
        $($divCollection[index]).removeClass('hide');

        $imgCollection.addClass('hide');
        $($imgCollection[index]).removeClass('hide');

        console.log($imgCollection[index]);
    }

    $('div#blockListOpenablesLinks a').click(function(e) {
        e.preventDefault();
        var index = $(this).index('div#blockListOpenablesLinks a');
        console.log(index);
        showElement(index,'dd');
    });


    //svg click
    $('div#blockListOpenablesLinks use').click(function(){

        $('div#blockListOpenablesLinks use').each(function() {
            this.href.baseVal = '#bioPlus';
        });

        this.href.baseVal='#bioPlusSel';
    });
}