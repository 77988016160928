


/*------------------------------*/
/* Animation Compteur */
/*------------------------------*/

countFunction = function(){

    $('.count').each(function () {

        if($(this).parent().hasClass('aos-animate') && $(this).parent().hasClass('loadCount')){

            $(this).prop('Counter',0).animate({
                Counter: $(this).text()
            }, {
                duration: 4000,
                easing: 'swing',
                step: function (now) {
                    $(this).text(Math.ceil(now));
                }
            });
            $(this).parent().removeClass('loadCount');
        }
    });

}

countFunctionDec = function(){

    $('.count-decimal').each(function () {
        if($(this).parent().hasClass('aos-animate') && $(this).parent().hasClass('loadCount')){


            $(this).prop('Counter', 0).animate({
                Counter: $(this).text()
            }, {
                duration: 4000,
                easing: 'swing',
                step: function (now) {
                    $(this).text(Math.ceil(now * 10) / 10);
                }
            });
            $(this).parent().removeClass('loadCount');
        }
    });

}

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});


/* ---------------------
Slider
--------------------- */
if($('.engagement_slider').length){

    $('#bxslider').bxSlider({
        pager: false,
        prevText: '',
        nextText: '',
        prevSelector: "#engagement_slider-prev",
        nextSelector: "#engagement_slider-next"
    });

    /* le slider bloque les animations de la page : bidouille pour les redéclencher */
    $(window).on('scroll', function () {
        if( ($(window).scrollTop() + $(window).height() ) > $('#bxslider').offset().top) {
            AOS.refresh();
        }
    });
}


// Menu Nos engagements
if ($('ul#followMenu').length > 0) {

    var offsetLeftcol = $('.engagements-content-leftcol').offset().top;

    if ($(window).scrollTop() > offsetLeftcol) {
        $('.engagements-content-leftcol').addClass('absolutemenu');
    }

    $(window).on('scroll', function () {
        if ($(window).scrollTop() > offsetLeftcol) {
            $('.engagements-content-leftcol').addClass('absolutemenu');
        } else {
            $('.engagements-content-leftcol').removeClass('absolutemenu');
        }
    });

    $('ul#followMenu a').on('click', function (e) {
        e.preventDefault();
        var target = $(this).attr('href');

        $('body, html').animate({'scrollTop': $(target).offset().top});
    });

}
