
var minHeightBlock = 80;
$('.block-container-icone-arrow').on('click', function () {
    baseBlockHeight = $(this).parent('.list-line').innerHeight();
    clickedBlockParent = $(this).parents('.list-line');
    if (clickedBlockParent.innerHeight() < minHeightBlock ) {
        clickedBlockParent.animate({'height': baseBlockHeight + 'px'}, 'slow').addClass("open");
    } else {
        clickedBlockParent.animate('slow').removeClass("open");
    }
});

if ($('.invoices-promotional-blocks').length && $(window).width() < 480) {
    $('.invoices-promotional-blocks').each(function () {
        var nbSlides = $(this).children('.bxslider').length;
        // console.log(nbSlides);
        if (nbSlides > 2) {
            var slider = $(this).bxSlider({
                minSlides: 1,
                maxSlide: 4,
                slideWidth: 320,
                prevText: '',
                nextText: '',
                responsive: true,
                wrapperClass: 'bx-wrapper bx-wrapper-invoice-slider'
            });
        }
        $(".my-img-class").css({
            "width": 320 + "px",
            "object-fit": "contain"
        });
    })
}
