const checkboxes = $("input.select-a:checkbox");
const checkAll = $('#select-all');
const inputSelectASelector = "input.select-a";
const actionGroupeesButtons = $('.ged-group-actions-icons-icon');
const numberSelected = ($(window).width() < 480) ? $("#number-of-selected-mob") : $("#number-of-selected");
let nbChecked = 0;

checkboxes.iCheck('uncheck');
numberSelected.hide();
// Adding listeners to cb to change classes
checkboxes.on('ifChecked', (elem) => {
    $(elem.target).closest('tr').addClass('list-line-selected');
    actionGroupeesButtons.removeAttr('disabled');
    updateRowSelected(1);
    stateRowAction($(elem.target), true);
});

checkboxes.on('ifUnchecked', (elem) => {
    $(elem.target).closest('tr').removeClass('list-line-selected');
    updateRowSelected(-1);
    stateRowAction($(elem.target), false);
});

checkAll.on('ifChecked', function(event) {
    if (event.type === 'ifChecked') {
        checkboxes.iCheck('check');
    }
});

checkAll.on('ifUnchecked', function() {
    checkboxes.iCheck('uncheck');
});

window.sendInvoicesLinks = (generateInvoicesLinksPath) => {
    if (!$(this).attr('disabled')) {
        let references = [];
        $(inputSelectASelector).each(function(){
            if (this.checked === true) {
                const type =
                    $(this).closest('tr').find('.list-line-cell-type').html().toString().includes('Contrat') ?
                        'contract' : 'invoice'
                ;
                references.push(type + '_' + this.value);
            }
        });

        openInvoicesLinksEmailWindow(generateInvoicesLinksPath, references.join('-'));
    }
};

window.sendInvoiceLink = (generateInvoicesLinksPath, type, reference) => {
    const documentType = type.includes('Facture') || type.includes('Avoir') ? 'invoice' : 'contract';

    openInvoicesLinksEmailWindow(generateInvoicesLinksPath, documentType + '_' + reference);
};

function openInvoicesLinksEmailWindow(invoicesLinksPath, references) {
    $.ajax({
        type: "POST",
        url: invoicesLinksPath,
        data: {
            'references': references
        },
        success: (result) => {
            if (result.success) {
                const mail = document.createElement("a");
                mail.href = "mailto:?body=" + result.html;
                mail.click();
            }
        },
        error: (error) => {
            console.log(error);
        }
    });
}

function stateRowAction(line, checked) {
    const actionCell = line.closest('tr').find('.actionCell');
    const actionCellLink = actionCell.find('a');
    const actionCellButton = actionCell.find('button');

    actionCellLink.each(function () {
        const jqueryElem = $(this);
        if (checked) {
            jqueryElem.attr('data-href', jqueryElem.attr('href'));
            jqueryElem.removeAttr('href');
        } else {
            jqueryElem.attr('href', jqueryElem.attr('data-href'));
            jqueryElem.removeAttr('data-href');
        }
    });

    actionCellButton.each(function() {
        const jqueryElem = $(this);
        if (checked) {
            jqueryElem.attr('disabled', 'disabled');
            jqueryElem.addClass('disabled');
        } else {
            jqueryElem.removeAttr('disabled');
            jqueryElem.removeClass('disabled');
        }
    })
}

function checkedAction(action) {
    $(inputSelectASelector).each(function(){
        if (this.checked === true) {
            const tr = $(this).closest('tr').find('.groupAction');
            if (action === 'dw') {
                const link = $(tr.find('a')[0]);
                const button = $(tr.find('button')[0]);
                link.attr('href', link.attr('data-href'));
                link.removeAttr('data-href');
                button.removeAttr('disabled');
                button.trigger('click');
                button.attr('disabled', 'disabled');
                link.attr('data-href', link.attr('href'));
                link.removeAttr('href');
            }

            if (action === 'pr') {
                $(tr.find('button')[1]).trigger('click');
            }
        }
    })
}

function updateRowSelected(valueUpdate) {
    nbChecked += valueUpdate;
    if (nbChecked < 0) {
        nbChecked = 0;
    }
    if (nbChecked === 0) {
        actionGroupeesButtons.attr('disabled', 'disabled');
        $($(".ged-group-actions-title")[0]).addClass('disabled');
        numberSelected.hide();
    } else {
        $($(".ged-group-actions-title")[0]).removeClass('disabled');
        numberSelected.show();
        numberSelected.text('Sélection: ' + nbChecked);
    }
}

window.viewDocuments = (viewDocumentsPath, action) => {
    if (!$(this).attr('disabled')) {
        let references = [];
        $(inputSelectASelector).each(function() {
            if (this.checked === true) {
                const type =
                    $(this).closest('tr').find('.list-line-cell-type').html().toString().includes('Contrat') ?
                        'contract' : 'invoice'
                ;
                references.push(type + '_' + this.value);
            }
        });

        if ('print' === action) {
            const printWindow = window.open(viewDocumentsPath + '?action=' + action + '&references=' + references.join('-'));
            printWindow.onload = () => printWindow.print();
            printWindow.onafterprint = () => printWindow.close();
        } else if ('download' === action) {
            const mergedFile = document.createElement('a');
            mergedFile.href = viewDocumentsPath + '?action=' + action + '&references=' + references.join('-');
            mergedFile.target = '_blank';
            mergedFile.download = 'documents.pdf';
            document.body.appendChild(mergedFile);
            mergedFile.click();
            document.body.removeChild(mergedFile);
        }
    }
};

window.sendIsProcessed = (setProcessedLinkPath, id) => {
    setProcessed(setProcessedLinkPath, id);
};

function setProcessed(setProcessedLinkPath, id) {
    $('#processed-action-' + id).addClass('xhr-loader');
    $.ajax({
        type: "POST",
        url: setProcessedLinkPath,
        data: {
        },
        success: (result) => {
            if (result.success) {
                if (result.value == true) {
                    $('#processed-yes-' + id).removeClass('hidden');
                    $('#processed-no-' + id).addClass('hidden');
                } else {
                    $('#processed-yes-' + id).addClass('hidden');
                    $('#processed-no-' + id).removeClass('hidden');
                }
            }
            $('#processed-action-' + id).removeClass('xhr-loader');

            if (result.message) {
                console.info(result.message);
            }
        },
        error: (error) => {
            $('#processed-action-' + id).removeClass('xhr-loader');
            console.error(error);
        }
    });
};
