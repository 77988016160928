/* Main entry point for JS personal scripts compilation */
global.jQuery = global.$ = require('jquery');
global.AOS = require('aos');
require('icheck');
require('selectric');
// require('tooltipster');
require('bxslider/dist/jquery.bxslider');
require('chart.js');
require('lity');
require('nicescroll');
require('sticky-kit/dist/sticky-kit');
require('bootstrap-datepicker');
require('./scripts/bootstrap-datepicker.fr');
require('./vendors/jquery.splitflap');
require('./vendors/bootstrap');

(function($) {
    $(document).ready(function () {
        require('./scripts/menu.js')
        require('./scripts/minute-pharma.js')
        require('./scripts/various.js')
        require('./scripts/engagement.js')
        require('./scripts/contratRegister.js')
        require('./scripts/catalogue.js')
        require('./scripts/commandes.js')
        require('./scripts/formations.js')
        require('./scripts/productAutocompleteSearch.js')
        require('./scripts/scli.js')
        require('./scripts/checkBrowser.js')
        require('./scripts/pharmacien.js')
        require('./scripts/pharmacien-equipe.js')
        require('./scripts/pharmacien-equipe-popin.js')
        require('./scripts/comprendre-rdp.js')
        require('./scripts/g361-cc-lib.js')
        require('./scripts/dispo-max.js')
        require('./scripts/datalayers.js')
        require('./scripts/ged_actions.js')
        require('./scripts/actionsGroupees.js')
        require('./scripts/digit-facture.js')
        require('./scripts/contrat.js')
    });
})(jQuery);

require('./scripts/engagement_js.js')
