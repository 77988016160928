require('./bootstrap/transition.js')
require('./bootstrap/transition.js')
require('./bootstrap/alert.js')
require('./bootstrap/button.js')
require('./bootstrap/carousel.js')
require('./bootstrap/collapse.js')
require('./bootstrap/dropdown.js')
require('./bootstrap/modal.js')
require('./bootstrap/tab.js')
require('./bootstrap/affix.js')
require('./bootstrap/scrollspy.js')
// require('./bootstrap/tooltip.js')
// require('./bootstrap/popover.js')
