(function ($) {
    var MembreAjax = function (url, id) {
        var _MembreAjax = function () {
            var self = this;

            var params = {
                url: url,
                id: id,

                debugMessageReady: "MembreAjax {{id}} ready!",
                debugMessageCall: "MembreAjax {{id}} {{type}} call [{{url}}]",
                debugMessageSuccess: "MembreAjax {{id}} {{type}} success!",
                debugMessageError: "MembreAjax {{id}} {{type}} error!",
            };

            this.init = function () {
                //console.log(Utils.mustache(params.debugMessageReady, {id: params.id}));
            };

            this.argFunction = function (f) {
                if (typeof f === "function") {
                    return f;
                }

                return function () {
                };
            };

            this.callRenvoyer = function (callbackSuccess, callbackError, data) {
                callbackSuccess = this.argFunction(callbackSuccess);
                callbackError = this.argFunction(callbackError);
                this.call("renvoyer", callbackSuccess, callbackError, data);
            };

            this.callSupprimer = function (callbackSuccess, callbackError, data) {
                callbackSuccess = this.argFunction(callbackSuccess);
                callbackError = this.argFunction(callbackError);
                this.call("supprimer", callbackSuccess, callbackError, data);
            };

            this.callEditer = function (callbackSuccess, callbackError, data) {
                callbackSuccess = this.argFunction(callbackSuccess);
                callbackError = this.argFunction(callbackError);
                this.call("editer", callbackSuccess, callbackError, data);
            };

            this.call = function (type, callbackSuccess, callbackError, data) {
                var url = Utils.underscore(params.url, {id: params.id, type: type});

                var paramsAjax = {
                    url: url,
                    type: "post",
                    success: function (result) {
                        callbackSuccess();
                    },
                    error: function (result) {
                        callbackError();
                    }
                };

                if (null !== data) {
                    paramsAjax["data"] = data;
                }

                $.ajax(paramsAjax);
            };

            this.init();
        };

        return new _MembreAjax();
    };

    var Utils = (function () {
        var _Utils = function () {
            var self = this;

            // https://gist.github.com/jimeh/332765
            this.mustache = function (string, data) {
                if (typeof (string) === "string" && typeof (data) === "object") {
                    for (var key in data) {
                        string = string.replace(new RegExp("{{\\s*" + key + "\\s*}}", "g"), data[key]);
                    }
                }
                return string;
            };

            this.underscore = function (string, data) {
                if (typeof (string) === "string" && typeof (data) === "object") {
                    for (var key in data) {
                        string = string.replace(new RegExp("__\\s*" + key + "\\s*__", "g"), data[key]);
                    }
                }
                return string;
            };

            this.arrayToJson = function (_array) {
                var _json = {};
                for (var i = 0; i < _array.length; i++) {
                    _json[_array[i]['name']] = _array[i]['value'];
                }
                return _json;
            };
        }

        return new _Utils();
    })();

    var Membre = function ($membre) {
        var _Membre = function () {
            var self = this;

            var params = {
                $membre: $membre,

                btnEditerPointer: ".editer",
                btnRenvoyerPointer: ".renvoyer",
                btnSupprimerPointer: ".supprimer",
                btnAnnulerPointer: ".annuler",
                btnValiderPointer: ".valider",
                valuePointer: ".value",
                fieldEditPointer: ".field-edit",
                editerOn: "editer-on",

                fieldValueNomPrenomPointer: ".cell-pharmacien-equipe-nom_prenom .value",
                fieldValueEmailPointer: ".cell-pharmacien-equipe-email .value",
                fieldValueEmailPointerInput: ".cell-pharmacien-equipe-email .field-edit input",
                fieldValueHasBpmPointer: ".cell-pharmacien-equipe-has-bpm .value",
                fieldValueHasBpmPointerInput: ".cell-pharmacien-equipe-has-bpm .field-edit input",

                allEventsLocked: false,

                messageSupprimer: "Souhaitez-vous supprimer '{{label}}' ?",
                messageSupprimerConfirmSuccess: "Le membre de l'équipe '{{label}}' a bien été supprimé",
                messageSupprimerConfirmError: "Le membre de l'équipe '{{label}}' n'a pas pu être supprimé",
                messageRenvoyer: "Souhaitez-vous renvoyer une invitation à '{{label}}' ?",
                messageRenvoyerConfirmSuccess: "Une invitation a été renvoyée à '{{label}}'",
                messageRenvoyerConfirmError: "L'invitation n'a pas pu être renvoyée à '{{label}}'",
                messageValiderConfirmSuccess: "Le membre de l'équipe '{{label}}' a bien été mis à jour",
                messageValiderConfirmError: "Le membre de l'équipe '{{label}}' n'a pas pu être mis à jour"
            };

            this.init = function () {
                params.$btnEditer = params.$membre.find(params.btnEditerPointer);
                params.$btnRenvoyer = params.$membre.find(params.btnRenvoyerPointer);
                params.$btnSupprimer = params.$membre.find(params.btnSupprimerPointer);
                params.$btnAnnuler = params.$membre.find(params.btnAnnulerPointer);
                params.$btnValider = params.$membre.find(params.btnValiderPointer);

                params.id = this.getId(params.$membre);
                params.label = this.getLabel(params.$membre);
                params.url = this.getUrl(params.$membre);
                params.ajax = new MembreAjax(params.url, params.id);

                this.initEvents();
            };

            this.areAllEventsUnlocked = function () {
                return !this.allEventsLocked;
            };

            this.lockAllEvents = function () {
                this.allEventsLocked = true;
            };

            this.unlockAllEvents = function () {
                this.allEventsLocked = false;
            };

            this.initEvents = function () {
                params.$btnEditer.click(function (e) {
                    e.preventDefault();
                    if (self.areAllEventsUnlocked()) {
                        params.$membre.addClass(params.editerOn);
                    }
                });

                params.$btnRenvoyer.click(function (e) {
                    e.preventDefault();
                    self.renvoyer();
                });

                params.$btnSupprimer.click(function (e) {
                    e.preventDefault();
                    self.supprimer();
                });

                params.$btnAnnuler.on("click", function (e) {
                    e.preventDefault();
                    self.editerOff();
                });

                params.$btnValider.on("click", function (e) {
                    e.preventDefault();
                    self.valider();
                });
            };

            this.editerOff = function () {
                params.$membre.removeClass(params.editerOn);
                this.unlockAllEvents();
            };

            this.getId = function ($item) {
                return $item.attr("data-id");
            };

            this.getLabel = function ($item) {
                return $item.attr("data-label");
            };

            this.setLabel = function ($item, label) {
                params.label = label;
                return $item.attr("data-label", label);
            };

            this.getUrl = function ($item) {
                return $item.attr("data-url");
            };

            this.renvoyer = function () {
                if (this.areAllEventsUnlocked()) {
                    this.lockAllEvents();

                    if (this.confirmMessage(params.messageRenvoyer, {label: params.label})) {
                        params.ajax.callRenvoyer(function () {
                            self.renvoyerConfirmSuccess();
                        }, function () {
                            self.renvoyerConfirmError();
                        }, null);
                    } else {
                        this.unlockAllEvents();
                    }
                }
            };

            this.renvoyerConfirmSuccess = function () {
                this.alertMessage(params.messageRenvoyerConfirmSuccess, {label: params.label})
                this.unlockAllEvents();
            };

            this.renvoyerConfirmError = function () {
                this.alertMessage(params.messageRenvoyerConfirmError, {label: params.label});
                this.unlockAllEvents();
            };


            this.supprimer = function () {
                if (this.areAllEventsUnlocked()) {
                    this.lockAllEvents();
                    //console.log("Membre supprimer", params.id);

                    if (this.confirmMessage(params.messageSupprimer, {label: params.label})) {
                        params.ajax.callSupprimer(function () {
                            self.supprimerConfirmSuccess();
                        }, function () {
                            self.supprimerConfirmError();
                        }, null);
                    } else {
                        this.unlockAllEvents();
                    }
                }
            };

            this.supprimerConfirmSuccess = function () {
                params.$membre.remove();
                this.alertMessage(params.messageSupprimerConfirmSuccess, {label: params.label})
                this.unlockAllEvents();
            };

            this.supprimerConfirmError = function () {
                this.alertMessage(params.messageSupprimerConfirmError, {label: params.label});
                this.unlockAllEvents();
            };

            this.annuler = function () {
                if (this.areAllEventsUnlocked()) {
                    //console.log("Membre annuler", params.id);
                }
            };

            this.valider = function () {
                if (this.areAllEventsUnlocked()) {
                    this.lockAllEvents();
                    //console.log("Membre valider", params.id);

                    var dataArray = params.$membre.find("input, select").serializeArray();

                    var email = dataArray.filter(function (input) {
                        return input.name == 'email';
                    })[0];

                    if (email && !((/^\w+([\.\+_-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(email.value))) {
                        alert("L'email " + email.value + " est invalide.");
                        this.unlockAllEvents();
                        return;
                    }
                    var data = Utils.arrayToJson(dataArray);

                    params.ajax.callEditer(function () {
                        self.updateMembre(data);
                        self.validerConfirmSuccess();
                        self.editerOff();
                    }, function () {
                        self.validerConfirmError();
                        self.editerOff();
                    }, data);
                }
            };

            this.updateMembre = function (data) {
                //console.log("updateMembre", data);

                if (data['nom'] !== null) {
                    params.$membre.find(params.fieldValueNomPrenomPointer).each(function () {
                        params.label = data['nom'] + ' ' + data['prenom']
                        $(this).text(params.label);
                    });
                }

                if (data['email'] !== null) {
                    params.$membre.find(params.fieldValueEmailPointer).each(function () {
                        $(this).text(data['email']);
                    });
                    // Modification de l'input text
                    params.$membre.find(params.fieldValueEmailPointerInput).each(function () {
                        $(this).attr('value', data['email']);
                    });
                    // On change le label au niveau du <ul></ul>
                    this.setLabel(params.$membre, data['email']);
                }

                if (data['has-bpm'] !== null) {
                    params.$membre.find(params.fieldValueHasBpmPointer).each(function () {
                        if (data['has-bpm'] == 'on') {
                            $(this).text('activé');
                        } else {
                            $(this).text('désactivé');
                        }
                    });
                    // Modification de l'input text
                    params.$membre.find(params.fieldValueHasBpmPointerInput).each(function () {
                        $(this).attr('value', data['hasBpm']);
                    });
                    // On change le label au niveau du <ul></ul>
                    this.setLabel(params.$membre, data['hasBpm']);
                }

                if (typeof data['officine'] !== 'undefined') {
                    var label = "";
                    var $options = params.$membre.find('.cell-pharmacien-equipe-officine select[name=officine] option');
                    var $officineText = params.$membre.find(".cell-pharmacien-equipe-officine .value");

                    $options.each(function () {
                        var $o = $(this);
                        if ($o.attr("value") === data["officine"]) {
                            label = $o.text();
                            $o.attr("selected", "selected");
                        } else {
                            $o.attr("selected", null);
                        }
                    });

                    $officineText.text(label);
                }
            };

            this.validerConfirmSuccess = function () {
                this.alertMessage(params.messageValiderConfirmSuccess, {label: params.label});
                this.unlockAllEvents();
            };

            this.validerConfirmError = function () {
                this.alertMessage(params.messageValiderConfirmError, {label: params.label});
                this.unlockAllEvents();
            };

            this.confirmMessage = function (string, data) {
                var message = Utils.mustache(string, data);
                return confirm(message);
            };

            this.alertMessage = function (string, data) {
                var message = Utils.mustache(string, data);
                alert(message);
            };

            this.init();
        };

        return new _Membre();
    };

    var Membres = (function () {
        var _Membres = function () {
            var self = this;

            var params = {
                membreEnAttentePointer: ".pharmacien-equipe-invitation_en_attente",
                membreMembrePointer: ".pharmacien-equipe-membre",
                membres: []
            };

            this.init = function () {
                params.allMembresPointer = params.membreEnAttentePointer + "," + params.membreMembrePointer;
                params.$membres = $(params.allMembresPointer);

                params.$membres.each(function () {
                    var $membre = $(this);
                    var membre = new Membre($membre);

                    params.membres.push(membre);
                });
            };

            this.init();
        };

        return new _Membres();
    })();
})($);
